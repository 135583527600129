import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { LoginService } from "src/app/core/login/login.service";
import { AppraisalService } from "src/app/core/services/appraisal.service";
import { LearnerService } from "src/app/core/services/learner.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { ESeparationService } from "src/app/core/services/e-separation.service"
import { PromotionHistoryComponent } from "../appraisal/promotion-history/promotion-history.component";
import { FormBuilder, FormGroup ,Validators} from '@angular/forms';

@Component({
  selector: 'app-e-separation',
  templateUrl: './e-separation.component.html',
  styleUrls: ['./e-separation.component.css']
})
export class ESeparationComponent implements OnInit {
  user: any;
  lndUserType: any;
  name: any;
  showcard: Boolean;
  showhr: Boolean;
  emp: any;
  showPOA:boolean;
  id: any;
  showAmdoc:boolean;
  exitForm: FormGroup;
  teamData: any;
  whatsappNo:number;
  personalEmail:any;
  exitReason:any;
  empData:any;
  calculatedExitDate:any;
  proposedDate: string = '';
  exitFormId: number;  
  constructor(
    private learnerService: LearnerService,
    private loginService: LoginService,
    private appraisalService: AppraisalService,
    public loaderService: LoaderService,
    private dialog: MatDialog,
    private fb: FormBuilder, 
    private eSeparationService: ESeparationService
  ) { }

  ngOnInit() {
     
       
    this.learnerService.getUserType().subscribe(res => {
        this.user = res['lnd_user_type'];
        this.lndUserType = true;
        localStorage.setItem('user_type', res['lnd_user_type']);
    },
        (error: any) => {
            console.log(error, error.error, error.message);
            if (error.error == 'Signature has expired') {
                this.loginService.logout();
            }
        });
    this.learnerService.getEmployeeDetails().subscribe((res: any) => {

        this.emp = res.data;
        this.name = res.data.name;
        this.id = res.data.emp_id;
        console.log(this.emp, "----------emp data")
    },
        (error: any) => {
            console.log(error, error.error, error.message);
            if (error.error == 'Signature has expired') {
                this.loginService.logout();
            }
        })
        this.eSeparationService.getTeamSeperation().subscribe((res: any) => {
              console.log(res);
             this.teamData=res.data;
          },
              (error: any) => {
                  console.log(error, error.error, error.message);
                  if (error.error == 'Signature has expired') {
                      this.loginService.logout();
                  }
              });
}

submitFormAcknowledge(status: string): void {
  const data = {
    proposed_date: this.proposedDate,
    exit_form_id: this.exitFormId,
    status: status
  };

  console.log('Payload:', data);

  this.eSeparationService.acknowledgeForm(data).subscribe({
    next: (response: any) => {
      console.log('Response:', response);
  
      if (response && response.status === true) {
          window.location.reload();
      } else {
        alert(response.message);
      }
    },
    error: (error) => console.error('Error:', error)
  });
}

getEmployeeExitDetails(id){
  this.exitFormId=id;
  this.eSeparationService.employeeExitDetails(id).subscribe((res: any) => {
    console.log(res)
    const { exit_reason, personal_email, whatsapp_no, calculated_exit_date } = res.data;
    this.empData = res.data;
    this.exitReason = exit_reason;
    this.personalEmail = personal_email;
    this.whatsappNo = whatsapp_no;
    this.calculatedExitDate = calculated_exit_date;

  },
     (error: any) => {
         console.log(error, error.error, error.message);
         if (error.error == 'Signature has expired') {
             this.loginService.logout();
         }
     });
  }

expensecardshow(showclaimdata) {
  this.showcard = showclaimdata;
}
hrmanagercardshow(showhrmanagerdata){
  this.showhr=showhrmanagerdata
  
}
poashow(showpoa){
  this.showPOA=showpoa;
}
amdocshow(showamdoc){
  this.showAmdoc=showamdoc;
}

}
