import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { LoginService } from "src/app/core/login/login.service";
import { AppraisalService } from "src/app/core/services/appraisal.service";
import { LearnerService } from "src/app/core/services/learner.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { ESeparationService } from "src/app/core/services/e-separation.service"
import { PromotionHistoryComponent } from "../appraisal/promotion-history/promotion-history.component";
import { FormBuilder, FormGroup ,Validators} from '@angular/forms';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
    user: any;
    lndUserType: any;
    name: any;
    showcard: Boolean;
    showhr: Boolean;
    emp: any;
    showPOA:boolean;
    id: any;
    showAmdoc:boolean;
    exitForm: FormGroup;
    finalLevel :any;
    steps: any;
    currentStep:any;
    noticePeriod:any;
    day: any;
    month:any;
    lastWorkingDayYYYY:any;
    canSubmit:any;
    msgApproval:string;
    constructor(
        private learnerService: LearnerService,
        private loginService: LoginService,
        private appraisalService: AppraisalService,
        public loaderService: LoaderService,
        private dialog: MatDialog,
        private fb: FormBuilder, 
        private eSeparationService: ESeparationService
    ) { }
    ngOnInit() {           
        this.learnerService.getUserType().subscribe(res => {          
            this.user = res['lnd_user_type'];
            this.lndUserType = true;
            localStorage.setItem('user_type', res['lnd_user_type']);
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
        this.learnerService.getEmployeeDetails().subscribe((res: any) => {
            let today = new Date();
            today.setDate(today.getDate() + res.data.notice_period_day);
            // Get the day, month, and year
            var day = today.getDate();
            var month = today.getMonth() + 1; // Months are 0-indexed, so add 1
            var year = today.getFullYear();
            // Ensure day and month are always 2 digits (e.g., 01, 02)
            if (day < 10) this.day = '0' + day;
            if (month < 10) this.month = '0' + month;
            // Log the updated date (optional: formatted to YYYY-MM-DD)
            let lastWorkingDay = `${day}-${month}-${year}`;
             this.lastWorkingDayYYYY = today.toISOString().split('T')[0]; // YYYY-MM-DD format
            // console.log("Last Working Day:", lastWorkingDay);
            this.noticePeriod = lastWorkingDay
            this.emp = res.data;
            this.name = res.data.name;
            this.id = res.data.emp_id;
            console.log(this.emp, "----------emp data")
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            })
        this.name = localStorage.getItem('name');
        this.exitForm = this.fb.group({
            exitReason: ['', Validators.required], // Exit reason is required but starts empty
            whatsAppNumber: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
            personalEmail: ['', [Validators.required, Validators.email]]
          });
          this.eSeparationService.getCanSubmit().subscribe((res: any) => {
            this.currentStep = res.data.final_level; //1 to 6
            this.canSubmit = res.data.can_submit;
            this.msgApproval =res.message;
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            })
            this.steps = ['E-Separation', 'Reporting Manager', 'HOD', 'Business Head', 'CEO', 'HR Level 1 Approval  (Initiating NDC)'];
    }

    getPromotions() {
        this.appraisalService.getPromotionHistory({}).subscribe((res: any) => {
            console.log(res);
            let selfData = res.data;
            this.loaderService.setLoading(true);
            let dialogRef = this.dialog.open(PromotionHistoryComponent, {
                data: {
                    name: this.name,
                    emp_id: this.id,
                    data: selfData,
                },
            });
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
    }

    expensecardshow(showclaimdata) {
        this.showcard = showclaimdata;
    }

    hrmanagercardshow(showhrmanagerdata) {
        this.showhr = showhrmanagerdata

    }
    poashow(showpoa) {
        this.showPOA = showpoa;
    }
    amdocshow(showamdoc){
        this.showAmdoc=showamdoc;
    }

    submitForm(): void {
        if (this.exitForm.valid) {
          const data = {            
            proposed_date: new Date().toISOString(),
            calculated_exit_date: this.lastWorkingDayYYYY,
            exit_reason: this.exitForm.value.exitReason,
            whatsapp_no: this.exitForm.value.whatsAppNumber,
            personal_email: this.exitForm.value.personalEmail,
            exit_type: 1
          };
         console.log(data)
          this.eSeparationService.submitExitForm(data).subscribe({
            next: (response: any) => {
                console.log('Response:', response);
            
                if (response && response.status === true) {
                    window.location.reload();
                } else {
                  alert(response.message);
                }
              },
            error: (error) => console.error('Error:', error)
          });
        }
      }
      showAlert(){
        alert(this.msgApproval)
      }




    //   viewDownloadPdf(file) {
    //     this.learnerService.getViewDownloadPdf(file).subscribe(res => {
    
    //       this.documents = res;
    //       // alert(this.documents.presigned_url);
    //       window.open(this.documents.presigned_url, "_blank");
    //       window.location.reload();
    //     },
    //       (error: any) => {
    //         console.log(error, error.error, error.message);
    
    //       });
    //   }


    }


