<ng-container *ngIf="!lndUserType && !loaderService.getLoading()">
    <div class="row">
        <div class="col" style="margin: 15vh 15vw 1vh; color: #777; font-size: 3vh; text-align: center;">
            Oops! It seems you are logged out. <br>
            Please Login again to continue :)
        </div>
    </div>
    <div class="row">
        <div class="col" style="margin: 1vh 15vw 15vh; text-align: center;">
            <button class="btn btn-primary" routerLink="/login" style="width: 30vw;">Go to Login</button>
        </div>
    </div>
</ng-container>
<span *ngIf="lndUserType">
    <app-header-global (notify)="expensecardshow($event)" (notifyhrmanager)="hrmanagercardshow($event)"
        (notifyPOA)="poashow($event)" (notifyAmdoc)="amdocshow($event)"></app-header-global>
</span>
<div class="row allcontent" *ngIf="lndUserType">
    <div class="col-sm-2">
        <app-side-overlay-menu [showClaim]="showcard" [showHr]="showhr" [showpoa]="showPOA"
            [showamdoc]="showAmdoc"></app-side-overlay-menu>
    </div>
    <div class="col-sm-10 contentsection">
        <div class="row">
            <div class="col header">
                Profile
            </div>
        </div>
        <div class="row profile-card" *ngIf="emp">
            <div class="col paddings">
                <div class="row">
                    <div class="col key">
                        Employee ID
                    </div>
                    <div class=" col-4 value">
                        {{emp.emp_id}}
                    </div>
                    <div class="col key">
                        Full Name
                    </div>
                    <div class=" col-4 value">
                        {{emp.name |titlecase}}
                    </div>
                </div>
                <div class="row">
                    <div class="col key">
                        Gender
                    </div>
                    <div class=" col-4 value">
                        {{emp.gender |titlecase}}
                    </div>
                    <div class="col key">
                        Designation
                    </div>
                    <div class=" col-4 value">
                        {{emp.job_title |titlecase}}
                    </div>
                </div>
                <div class="row">
                    <div class="col key">
                        Department
                    </div>
                    <div class=" col-4 value">
                        {{emp.department |titlecase}}
                    </div>
                    <div class="col key">
                        Business
                    </div>
                    <div class=" col-4 value">
                        {{emp.business |titlecase}}
                    </div>
                </div>
                <div class="row">
                    <div class="col key">
                        Mobile No.
                    </div>
                    <div class=" col-4 value">
                        {{emp.mobile_no}}
                    </div>
                    <div class="col key">
                        Email
                    </div>
                    <div class=" col-4 value">
                        {{emp.email}}
                    </div>
                </div>
                <div class="row">
                    <div class="col key">
                        Date of Birth
                    </div>
                    <div class=" col-4 value">
                        {{emp.dob}}
                    </div>
                    <div class="col key">
                        Date of Joining
                    </div>
                    <div class=" col-4 value">
                        {{emp.doj}}
                    </div>
                </div>
                <div class="row">
                    <div class="col key">
                        Qualification(UG)
                    </div>
                    <div class="col-4 value">
                        {{emp.qualification_ug}}
                    </div>
                    <div class="col key">
                        Qualification(PG)
                    </div>
                    <div class="col-4 value">
                        {{emp.qualification_pg}}
                    </div>
                </div>
                <div class="row">
                    <div class="col key">
                        Reporting Manager
                    </div>
                    <div class=" col-4 value">
                        {{emp.manager}}: {{emp.manager_name |titlecase}}
                    </div>
                    <div class="col key">
                        <button *ngIf="canSubmit" type="button" class="btn e-SeparationBtn" 
                            data-bs-toggle="modal" data-bs-target="#e-Separation">
                            E - Separation
                        </button>
                        <button *ngIf="!canSubmit" type="button" class="btn e-SeparationBtn" (click)="showAlert()">
                            E - Separation
                        </button>
                    </div>
                    
                    <div class="col-4 value">

                    </div>
                </div>
                <div class="row">
                    <div class="col-3 promotion" (click)="getPromotions()">
                        Promotion History
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="currentStep > 0" class="col-sm-12 stepsBg">
            <div class="stepContainer">
                <ng-container *ngFor="let step of steps; let i = index">
                    <!-- Apply green class only for steps less than or equal to currentStep -->
                    <div [ngClass]="{'circle': true, 'green': i < currentStep}">{{ i + 1 }}</div>
                    <div *ngIf="i < steps.length - 1" class="line"></div>
                    <div class="text">{{ step }}</div>
                </ng-container>
            </div>
        </div>

        <div *ngIf="currentStep === 6" class="col-12 interviewForm">
            <table>
                <tr>
                    <th>Types of Forms</th>
                    <th>Download</th>
                    <th>Upload</th>
                </tr>
                <tr>
                    <td>Exit Interview Form</td>
                    <td><a class="downloadBtn">Download</a> </td>
                    <td><a class="uploadBtn">Upload</a></td>
                </tr>
                <tr>
                    <td>Gratuity & Leave Incashment Form</td>
                    <td><a class="downloadBtn">Download</a> </td>
                    <td><a class="uploadBtn">Upload</a></td>
                </tr>
                <tr>
                    <td>Investment declaration form with proof</td>
                    <td><a class="downloadBtn">Download</a> </td>
                    <td><a class="uploadBtn">Upload</a></td>
                </tr>
            </table>
        </div>
        <div *ngIf="currentStep === 6" class="col-12">
            <p class="red">
                Note : Please upload your relevant documents before your exit date
            </p>
        </div>
    </div>
</div>
<div class="modal fade" id="e-Separation" tabindex="-1" aria-labelledby="e-SeparationLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="e-SeparationLabel">E - Separation</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="exitForm">
                    <div class="row">
                        <div class="col-12">
                            <textarea required formControlName="exitReason" class="form-control" id="message-text"
                                rows="8" placeholder="Enter your reason here..."></textarea>
                            <div
                                *ngIf="exitForm.get('exitReason').invalid && (exitForm.get('exitReason').dirty || exitForm.get('exitReason').touched)">
                                <small *ngIf="exitForm.get('exitReason').hasError('required')" class="text-danger">
                                    Exit reason is required.
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="row userEnter">
                        <div class="col-6">
                            <label>WhatsApp No.</label>
                            <input formControlName="whatsAppNumber" type="text" class="form-control"
                                id="whatsApp-number" required>
                            <div
                                *ngIf="exitForm.get('whatsAppNumber').invalid && (exitForm.get('whatsAppNumber').dirty || exitForm.get('whatsAppNumber').touched)">
                                <small *ngIf="exitForm.get('whatsAppNumber').hasError('required')" class="text-danger">
                                    WhatsApp number is required.
                                </small>
                                <small *ngIf="exitForm.get('whatsAppNumber').hasError('pattern')" class="text-danger">
                                    Must be a 10-digit number.
                                </small>
                            </div>
                        </div>
                        <div class="col-6">
                            <label>Add personal E-Mail Id</label>
                            <input formControlName="personalEmail" type="text" class="form-control" id="personal-E-Mail"
                                required>
                            <div
                                *ngIf="exitForm.get('personalEmail').invalid && (exitForm.get('personalEmail').dirty || exitForm.get('personalEmail').touched)">
                                <small *ngIf="exitForm.get('personalEmail').hasError('required')" class="text-danger">
                                    Email is required.
                                </small>
                                <small *ngIf="exitForm.get('personalEmail').hasError('email')" class="text-danger">
                                    Must be a valid email address.
                                </small>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer-e-Separation">
                <div class="row">
                    <div class="col-6" style="font-weight: 500;">
                        <span class="exitDate">Exit Date : {{noticePeriod}}</span>
                    </div>
                    <div class="col-6 bottomBtn">
                        <button type="button" class="btn acknowledge">Cancel</button>
                        <button type="button" class="btn reject" (click)="submitForm()" [disabled]="exitForm.invalid"
                            data-bs-dismiss="modal">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>