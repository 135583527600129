<app-header-global (notify)="expensecardshow($event)" (notifyhrmanager)="hrmanagercardshow($event)"
    (notifyPOA)="poashow($event)" (notifyAmdoc)="amdocshow($event)"></app-header-global>

<div class="row allcontent">
    <div class="col-sm-2">
        <app-side-overlay-menu [showClaim]="showcard" [showHr]="showhr" [showpoa]="showPOA"
            [showamdoc]="showAmdoc"></app-side-overlay-menu>
    </div>

    <div class="col-sm-10">
        <div class="row empList">
            <div class="col-10">
                <h4>List of Employees</h4>
            </div>
            <div class="col-2" style="text-align: right;">
                <!-- <a class="ndcBtn">NDC Section</a> -->
            </div>
        </div>

        <table class="employeeTable">
            <tr>
                <th>Employee Name</th>
                <th>Designation</th>
                <th>Department Manager</th>
                <th>Date of Joining</th>
                <th>Exit Date</th>
                <th>Status</th>
            </tr>
            <tr *ngFor="let item of teamData">
                <td>{{ item.employee_name }}</td>
                <td>{{ item.designation }}</td>
                <td>{{ item.manager }}</td>
                <td>{{ item.doj }}</td>
                <td>{{ item.exit_date }}</td>


                <td>
                    <button (click)="getEmployeeExitDetails(item.exit_form_id)" 
                        data-bs-toggle="modal" 
                        data-bs-target="#e-Separation" 
                        [ngClass]="{
                            'btn-success approved': item.current_status === 'Approved',
                            'btn-warning pending': item.current_status === 'Pending',
                            'btn-danger rejected': item.current_status === 'Rejected'
                        }" 
                        [attr.disabled]="!item.action_required ? true : null"
                        class="btn actionBtn">
                        <ng-container [ngSwitch]="item.current_status">
                            <span *ngSwitchCase="'Approved'">Approved</span>
                            <span *ngSwitchCase="'Pending'">Pending by {{ item.current_approval_level }}</span>
                            <span *ngSwitchCase="'Rejected'">Rejected by {{ item.current_approval_level }}</span>
                        </ng-container>
                    </button>
                </td>

                <!-- <td>{{ item.current_approval_level }}</td>
                <td>{{ item.action_required ? 'Yes' : 'No' }}</td> -->
            </tr>

        </table>





    </div>



    <!-- Modal -->
    <div class="modal fade" id="e-Separation" tabindex="-1" aria-labelledby="e-SeparationLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="e-SeparationLabel">E - Separation</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="exitForm">
                        <div class="row">
                            <div class="col-12">
                                <textarea required formControlName="exitReason" class="form-control" id="message-text"
                                    rows="8" placeholder="Enter your reason here..." value="{{exitReason}}" disabled></textarea>
                            </div>
                        </div>

                        <div class="row userEnter">
                            <div class="col-6">
                                <label>WhatsApp No.</label>
                                <input formControlName="whatsAppNumber" type="text" class="form-control"
                                    id="whatsApp-number" value="{{whatsappNo}}" disabled>

                            </div>

                            <div class="col-6">
                                <label>Add personal E-Mail Id</label>
                                <input formControlName="personalEmail" type="text" class="form-control"
                                    id="personal-E-Mail" value="{{personalEmail}}" disabled>

                            </div>
                        </div>
                    </form>

                </div>
                <div class="modal-footer-e-Separation">
                    <div class="row">
                        <div class="col-8" style="font-weight: 500;">
                            <div class="row">
                                <div class="col-4">
                                    <span class="exitDate">Exit Date : {{calculatedExitDate}}</span>
                                </div>
                                <div class="col-8">
                                    Proposed exit date : <input value="{{calculatedExitDate}}" type="date" class="form-control" id="proExDate"  [(ngModel)]="proposedDate">
                                </div>
                            </div>
                        </div>
                        <div class="col-4 bottomBtn">
                            <button type="button" class="btn acknowledge" data-bs-dismiss="modal" aria-label="Close" (click)="submitFormAcknowledge('Rejected')">Reject</button>
                            <button type="button" class="btn reject" data-bs-dismiss="modal" (click)="submitFormAcknowledge('Approved')">
                                Acknowledge
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

