import { Component, Inject, OnInit } from "@angular/core";
import { HrserviceService } from "../../hr-manager/service/hrservice.service";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Notification, Subject } from "rxjs";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, Validators } from "@angular/forms";
import { LoginService } from "src/app/core/login/login.service";
import { AppraisalService } from "src/app/core/services/appraisal.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { ToastrService } from "ngx-toastr";
@Component({
    selector: "app-company-dialog",
    templateUrl: "./company-dialog.component.html",
    styleUrls: ["./company-dialog.component.scss"],
})


export class CompanyDialogComponent implements OnInit {
    companyType: any;
    isValuearray: boolean = false;
    form: any;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<CompanyDialogComponent>,
        private dialog: MatDialog,
        private fb: FormBuilder,
        private loginService: LoginService,
        private appraisalService: AppraisalService,
        private loaderService: LoaderService,
        private hrService: HrserviceService,
        private toast: ToastrService
    ) { }

    ngOnInit() {
        this.form = this.fb.group({
            company_id: [null],
            name: ['', Validators.required],
            address: [null],
            code: [null, Validators.required],
            company_type: [null, Validators.required]
        });
        console.log(this.data)
        setTimeout(() => {
            this.loaderService.setLoading(false);
        }, 100);
        this.hrService.getCompanies().subscribe((res: any) => {
            this.companyType = res.data.company_type;

            if (this.data.type == 'edit') {
                this.form.patchValue({
                    company_id: this.data.data.company_id,
                    name: this.data.data.name,
                    address: this.data.data.address,
                    code: this.data.data.code,
                    company_type: this.data.data.company_type
                })
            }
        });

    }

    onSubmit() {
        console.log(this.form.value);
        console.log(this.form.valid)
        if (this.form.valid) {
            if (this.data.type == 'edit') {
                this.hrService.updateCompany(this.form.value).subscribe((res: any) => {
                    this.toast.success(res.message);
                    this.dialogRef.close();
                    setTimeout(() => {
                        window.location.reload();
                    }, 600)
                },
                    (error: any) => {
                        this.toast.error(error.error.data);
                    });
            }
            if (this.data.type == 'create') {
                this.hrService.createCompany(this.form.value).subscribe((res: any) => {
                    this.toast.success("Yay!!! Company Added!");
                    this.dialogRef.close();
                    setTimeout(() => {
                        window.location.reload();
                    }, 600)
                },
                    (error: any) => {
                        this.toast.error(JSON.stringify(error.error.data));
                    })
            }
        }
        else {
            this.toast.error("Form Invalid. Please Check!!!")
        }

    }
    cancel() {
        this.dialogRef.close();
    }

}