import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ESeparationService {
  getEmployeeDetailsURL="separation/team_seperation/";
  getCanSubmitURL='separation/can_submit/';
  submitExitFormURL='separation/submit_exit_form/';
  baseUrl = environment.url;
  sessionUrl = 'lnd/sessions/';
  employeeExitDetailsURL ='separation/exit_form_details/'
  acknowledgeURL='separation/approval/'
  accessToken = localStorage.getItem('accessToken');
  constructor(
    private http: HttpClient

  ) { 

  
  }
  
  

  submitExitForm(data) {
    const headers = new HttpHeaders().set('Authorization', this.accessToken);
    return this.http.post(this.baseUrl + this.submitExitFormURL, data, { headers });
}

acknowledgeForm(data) {
  const headers = new HttpHeaders().set('Authorization', this.accessToken);
  return this.http.post(this.baseUrl + this.acknowledgeURL, data, { headers });
}

  getTeamSeperation(){
    const headers = new HttpHeaders().set('Authorization', this.accessToken);
    return this.http.get(this.baseUrl + this.getEmployeeDetailsURL, { headers });
  }

  getCanSubmit(){
    const headers = new HttpHeaders().set('Authorization', this.accessToken);
    return this.http.get(this.baseUrl + this.getCanSubmitURL, { headers });
  }


  employeeExitDetails(id){
    const headers = new HttpHeaders().set('Authorization', this.accessToken);
    return this.http.get(this.baseUrl + this.employeeExitDetailsURL+id, { headers });
  }


}
