import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PortalPageComponent } from './components/portal-page/portal-page.component';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UpcomingSessionsComponent } from './components/upcoming-sessions/upcoming-sessions.component';
import { DraftsComponent } from './components/drafts/drafts.component';
import { YourSessionsComponent } from './components/your-sessions/your-sessions.component';
import { PastSessionsComponent } from './components/past-sessions/past-sessions.component';
import { SessionModalComponent } from './components/session-modal/session-modal.component';
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatRadioButton, MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTabsModule } from "@angular/material/tabs";
import { MatMenuModule } from "@angular/material/menu";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonToggle, MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatAccordion, MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { ConfirmationPopupComponent } from './components/confirmation-popup/confirmation-popup.component';
import { ParticipantListComponent } from './components/participant-list/participant-list.component';
import { MsalGuard } from '@azure/msal-angular';
import { LoginComponent } from './core/login/login.component';
import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { RegisterPopupComponent } from './components/register/register.component';
import { UnenrollPopupComponent } from './components/unenroll-popup/unenroll-popup.component';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { FeedbackPopupComponent } from './components/feedback-popup/feedback-popup.component';
import { ViewSessionComponent } from './components/view-session/view-session.component';
import { ActionPopupComponent } from './components/action/action.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { LoadingInterceptor } from './core/services/loading.interceptor';
import { SpinnerComponent } from './core/spinner/spinner.component';
import { AllLinksComponent } from './core/all-links/all-links.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatNativeDateModule } from '@angular/material/core';
import { SideOverlayMenuComponent } from './components/side-overlay-menu/side-overlay-menu.component';
import { EmployeeDocumentsComponent } from './components/employee-documents/employee-documents.component';
import { AllClaimComponent } from './components/expense-manager/all-claim/all-claim.component';
import { ClaimDetailsComponent } from './components/expense-manager/claim-details/claim-details.component';
import { ExpenseDetailsComponent } from './components/expense-manager/expense-details/expense-details.component';
import { HeaderComponent } from './components/expense-manager/header/header.component';
import { PreAprovedClaimComponent } from './components/expense-manager/pre-aproved-claim/pre-aproved-claim.component';
import { EmpolyeeListComponent } from './components/hr-manager/empolyee-list/empolyee-list.component';
import { HolidayListComponent } from './components/hr-manager/holiday-list/holiday-list.component';
import { HrmanagerComponent } from './components/hr-manager/hrmanager/hrmanager.component';
import { HrHeaderComponent } from './components/hr-manager/hr-header/hr-header.component';
import { LeavesReportComponent } from './components/hr-manager/leaves-report/leaves-report.component';
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { NgxPaginationModule } from 'ngx-pagination';
import { ConfirmationDialogComponent } from './components/expense-manager/confirmation-dialog/confirmation-dialog.component';
import { RejectionReasonDialogComponent } from './components/expense-manager/rejection-reason-dialog/rejection-reason-dialog.component';
import { HeaderGlobalComponent } from './components/header-global/header-global.component';
import { ProductlistComponent } from './components/productlist/productlist.component';
import { EventCalenderComponent } from './components/event-calender/event-calender.component';
import { EventPopupComponent } from './components/event-popup/event-popup.component';
import { CircularComponent } from './components/circular/circular.component';
import { MemoriesComponent } from './components/all-memories/memories/memories.component';
import { MemoriesDetailsComponent } from './components/all-memories/memories-details/memories-details.component';
import { MemoriesGalleryComponent } from './components/all-memories/memories-gallery/memories-gallery.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ViewPoaComponent } from './components/poa/view-poa/view-poa.component';
import { RaisePoaComponent } from './components/poa/raise-poa/raise-poa.component';
import { MainPoaComponent } from './components/poa/main-poa/main-poa.component';
import { NotificationModuleComponent } from './components/notification-module/notification-module.component';
import { TemplateModuleComponent } from './components/template-module/template-module.component'
import { AppraisalComponent } from './components/appraisal/appraisal.component';
import { ProfileComponent } from './components/profile/profile.component';
import { SelfAppraisalComponent } from './components/appraisal/self-appraisal/self-appraisal.component';
import { TeamAppraisalComponent } from './components/appraisal/team-appraisal/team-appraisal.component';
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { TeamAppraisalFormComponent } from './components/appraisal/team-appraisal/team-appraisal-form/team-appraisal-form.component';
import { ClusterAppraisalComponent } from './components/appraisal/cluster-appraisal/cluster-appraisal.component';
import { BulkSubmitComponent } from './components/appraisal/bulk-submit/bulk-submit.component';
import { BusinessAppraisalComponent } from './components/appraisal/business-appraisal/business-appraisal.component';
import { CompanyAppraisalComponent } from './components/appraisal/company-appraisal/company-appraisal.component';
import { ViewSelfAppraisalComponent } from './components/appraisal/self-appraisal-popup/self-appraisal-popup.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { DepartmentAppraisalFormComponent } from './components/appraisal/cluster-appraisal/department-appraisal-form/department-appraisal-form.component';
import { BusinessAppraisalFormComponent } from './components/appraisal/business-appraisal/business-appraisal-form/business-appraisal-form.component';
import { ViewTeamAppraisalComponent } from './components/appraisal/team-appraisal/team-appraisal-popup/team-appraisal-popup.component';
import { BUBulkSubmitComponent } from './components/appraisal/bu-bulk-submit/bu-bulk-submit.component';
import { PromotionHistoryComponent } from './components/appraisal/promotion-history/promotion-history.component';
import { AuthInterceptorService } from './core/services/auth-interceptor.service';
import { InputTextComponent } from './components/input-text/input-text.component';
import { InputDateComponent } from './components/input-date/input-date.component';
import { InputFileComponent } from './components/input-file/input-file.component';
import { InputNumberComponent } from './components/input-number/input-number.component';
import { SaveTemplateComponent } from './components/template-module/save-template/save-template.component';
import { ViewTemplateComponent } from './components/template-module/view-template/view-template.component';
import { InputMultipleFileComponent } from './components/input-multiple-file/input-multiple-file.component';
import { HrHomeComponent } from './components/employee-onboarding/hr-home/hr-home.component';
import { VerifyComponent } from './components/employee-onboarding/verify-user-data/verif/verif.component';
import { UserDialogComponent } from './components/employee-onboarding/user-dialog/user-dialog.component';
import { VerifyDialogComponent } from './components/employee-onboarding/verify-user-data/verif/verif.component';
import { EmpAddComponent } from './components/employee-onboarding/add-to-emp/add-to-emp.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputDropdownComponent } from './components/input-dropdown/input-dropdown.component';
import { ViewDepartmentAppraisalComponent } from './components/appraisal/cluster-appraisal/department-appraisal-popup/department-appraisal-popup.component';
import { EmpolyeeListComponent2 } from './components/hr-manager2/empolyee-list/empolyee-list.component';
import { HolidayListComponent2 } from './components/hr-manager2/holiday-list/holiday-list.component';
import { OnboardingComponent } from './components/hr-manager2/onboarding/onboarding.component';
import { HrmanagerComponent2 } from './components/hr-manager2/hrmanager/hrmanager.component';
import { VerifyComponent2, VerifyDialogComponent2 } from './components/hr-manager2/verify-user-data/verif/verif.component';
import { ErrorDialogComponent } from './components/employee-onboarding/error-dialog/error-dialog.component';
import { CompanyListComponent } from './components/hr-manager2/company-list/company-list.component';
import { CompanyDialogComponent } from './components/hr-manager2/company-dialog/company-dialog.component';
import { BusinessListComponent } from './components/hr-manager2/business-list/business-list.component';
import { BusinessDialogComponent } from './components/hr-manager2/business-dialog/business-dialog.component';
import { DepartmentDialogComponent } from './components/hr-manager2/department-dialog/department-dialog.component';
import { DepartmentListComponent } from './components/hr-manager2/department-list/department-list.component';
import { ESeparationComponent } from './components/e-separation/e-separation.component';
// import { EmpolyeeListComponent2 } from './components/hr-manager2/empolyee-list/empolyee-list.component';
// import { HolidayListComponent2 } from './components/hr-manager2/holiday-list/holiday-list.component';
// import { OnboardingComponent } from './components/hr-manager2/onboarding/onboarding.component';
// import { HrmanagerComponent2 } from './components/hr-manager2/hrmanager/hrmanager.component';
// import { VerifyComponent2, VerifyDialogComponent2 } from './components/hr-manager2/verify-user-data/verif/verif.component';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    // auth: {
    //   clientId: '05e97722-79e4-44ad-bb18-c08556c84b53', // Client ID of your Azure AD App
    //   authority: 'https://login.microsoftonline.com/7e38ac4f-7fbe-46ad-af19-8abc8322d9ed/', // e.g. https://login.microsoftonline.com/yourtenant.onmicrosoft.com
    //   redirectUri: 'https://empower.amplussolar.com/', // e.g. http://localhost:4200   https://empower.amplussolar.com/
    // },
    // cache: {
    //   cacheLocation: 'localStorage',
    //   storeAuthStateInCookie: true, // Set to true for IE 11
    // },
    auth: {
      // clientId: 'e16b09b4-ef62-4403-8ccf-34c962de9209', // Client ID of your Azure AD App
      // clientId: 'e16b09b4-ef62-4403-8ccf-34c962de9209/read',
      clientId: 'e16b09b4-ef62-4403-8ccf-34c962de9209',
      authority: 'https://login.microsoftonline.com/7e38ac4f-7fbe-46ad-af19-8abc8322d9ed/', // e.g. https://login.microsoftonline.com/yourtenant.onmicrosoft.com
      redirectUri: 'https://staging-empower.amplussolar.com/', // e.g. http://localhost:4200   https://staging-empower.amplussolar.com/
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true, // Set to true for IE 11
    },
  });
}

@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    AppComponent,
    PortalPageComponent,
    UpcomingSessionsComponent,
    DraftsComponent,
    YourSessionsComponent,
    PastSessionsComponent,
    SessionModalComponent,
    ConfirmationPopupComponent,
    ParticipantListComponent,
    LoginComponent,
    RegisterPopupComponent,
    UnenrollPopupComponent,
    FeedbackPopupComponent,
    ViewSessionComponent,
    ActionPopupComponent,
    FeedbackComponent,
    SpinnerComponent,
    AllLinksComponent,
    SideOverlayMenuComponent,
    EmployeeDocumentsComponent,
    AllClaimComponent,
    ClaimDetailsComponent,
    ExpenseDetailsComponent,
    HeaderComponent,
    PreAprovedClaimComponent,
    EmpolyeeListComponent,
    HolidayListComponent,
    HrmanagerComponent,
    HrHeaderComponent,
    LeavesReportComponent,
    ConfirmationDialogComponent,
    RejectionReasonDialogComponent,
    HeaderGlobalComponent,
    ProductlistComponent,
    EventPopupComponent,
    CircularComponent,
    MemoriesComponent,
    MemoriesDetailsComponent,
    MemoriesGalleryComponent,
    ViewSelfAppraisalComponent,
    ViewPoaComponent,
    RaisePoaComponent,
    MainPoaComponent,
    NotificationModuleComponent,
    TemplateModuleComponent,
    ViewTeamAppraisalComponent,
    AppraisalComponent,
    ProfileComponent,
    TeamAppraisalComponent,
    ClusterAppraisalComponent,
    BulkSubmitComponent,
    TeamAppraisalFormComponent,
    BusinessAppraisalComponent,
    CompanyAppraisalComponent,
    DepartmentAppraisalFormComponent,
    BusinessAppraisalFormComponent,
    BUBulkSubmitComponent,
    PromotionHistoryComponent,
    InputTextComponent,
    InputDateComponent,
    InputFileComponent,
    InputNumberComponent,
    SaveTemplateComponent,
    ViewTemplateComponent,
    InputMultipleFileComponent,
    HrHomeComponent,
    VerifyComponent,
    UserDialogComponent,
    VerifyDialogComponent,
    EmpAddComponent,
    InputDropdownComponent,
    ViewTeamAppraisalComponent,
    ViewDepartmentAppraisalComponent,
    EmpolyeeListComponent2,
    HolidayListComponent2,
    OnboardingComponent,
    HrmanagerComponent2,
    VerifyComponent2,
    VerifyDialogComponent2,
    ErrorDialogComponent,
    CompanyListComponent,
    CompanyDialogComponent,
    BusinessListComponent,
    BusinessDialogComponent,
    DepartmentDialogComponent,
    DepartmentListComponent,
    ESeparationComponent,
    // EmpolyeeListComponent2,
    // HolidayListComponent2,
    // OnboardingComponent,
    // HrmanagerComponent2,
    // VerifyComponent2,
    // VerifyDialogComponent2
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatMenuModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatRadioModule,
    MatCheckboxModule,
    MatInputModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatTableModule,
    MatIconModule,
    // MatTableDataSource,x
    MatExpansionModule,
    NgxStarRatingModule,
    MatNativeDateModule,
    MatTooltipModule,
    NgxPaginationModule,
    EventCalenderComponent,
    NgxMaterialTimepickerModule,
    SlickCarouselModule,
    SelfAppraisalComponent,
    DynamicFormComponent,
    NgxDatatableModule,
    ToastrModule.forRoot(),
    NgSelectModule,

  ],
  exports: [MatDialogModule],
  providers: [{ provide: MAT_DIALOG_DATA, useValue: {} },
  { provide: MatDialogRef, useValue: {} },
    MsalGuard,
    MsalService,
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory,
  },
  {
    provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
  },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
  { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }

  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AppComponent,
    PortalPageComponent,
    UpcomingSessionsComponent,
    DraftsComponent,
    YourSessionsComponent,
    PastSessionsComponent,
    SessionModalComponent,
    ConfirmationPopupComponent,
    ParticipantListComponent,
    LoginComponent,
    RegisterPopupComponent,
    UnenrollPopupComponent,
    FeedbackPopupComponent,
    ViewSessionComponent,
    ActionPopupComponent,
    FeedbackComponent,
    SpinnerComponent,
    AllLinksComponent,
    SideOverlayMenuComponent,

    AppraisalComponent,
    ProfileComponent,
    SelfAppraisalComponent
  ],
})
export class AppModule { }
